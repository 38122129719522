import Vue from 'vue'
import Router from 'vue-router'

import Default from './components/template/Default'
// import Menu from './components/dashboard/Menu'


Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
        path: '/',
        base: __dirname,
        meta: {
            title: 'Awaken Marketing Digital | Nós ajudamos a sua empresa a Conquistar Mais Clientes através de anúncios no Google',
            metaTags: [
              {
                name: 'description',
                content: 'Nós ajudamos a sua empresa a Conquistar Mais Clientes através de anúncios no Google'
              },
              {
                property: 'og:description',
                content: 'Nós ajudamos a sua empresa a Conquistar Mais Clientes através de anúncios no Google'
              }
            ]
          },
        // title: 'Awaken Marketing Digital',
        components: {default: Default},
        props: true,
        // children: [
        //     {path: '/', component: DefaultLogin, props: true},
        //     {path: '/esqueci-senha', component: FormEsqueci, props: true}
        // ]
    },
    
    // // {
    // //     path: '/esqueci-senha',
    // //     base: __dirname,
    // //     component: FormEsqueci,
    // //     props: true
    // // },
    // {
    //     // path: '/dashboard',
    //     // // component: Default,
    //     // component: Default,
    //     // children: [
    //     //     {path: '/', components: {conteudo: Dashboard}}
    //     // ]
    // },
    // // {
    // //     path: '/logout',
    // //     redirect: '/'
    // // },
    // {
    //     // path: '/404',
    //     // component: Dash
    // },
    // // {
    // //     path: '*',
    // //     redirect: '/404'
    // // },

    
    ]
})
