export default [
    {
        url: "https://skillshop.exceedlms.com/student/award/4iUgMdQ3a4enENwr2XDRGhnb",
        img: "./g-rede.png",
        titulo: "Certificação em rede de Pesquisa do Google Ads",
        recebido: "Ago 6,2021",
        expira: "Ago 6, 2022",
    },
    {
        url: "https://skillshop.exceedlms.com/student/award/uVio5iZrQMPoJBakUW5aEngn",
        img: "./g-display.png",
        titulo: "Certificação em Display do Google Ads",
        recebido: "Jan 20,2022",
        expira: "Jan 20, 2023",
    },
    {
        url: "https://skillshop.exceedlms.com/student/award/fE73MuViKvtzzECCDQ67yr9s",
        img: "./g-videos.png",
        titulo: "Certificação em vídeo do Google Ads",
        recebido: "Jan 6,2022",
        expira: "Jan 6, 2023",
    },
    {
        url: "https://skillshop.exceedlms.com/student/award/5mKAPVKYd37qJh1oNaC3Q37p",
        img: "./g-shopping.png",
        titulo: "Certificação em anúncios do Shopping",
        recebido: "Jan 20,2022",
        expira: "Jan 20, 2023",
    },
    {
        url: "https://skillshop.exceedlms.com/student/award/govvU2n9im1h8eh6JwFQCa5j",
        img: "./g-apps.png",
        titulo: "Certificação em apps do Google Ads",
        recebido: "Fev 1,2022",
        expira: "Fev 1, 2023",
    },
    {
        url: "https://skillshop.exceedlms.com/student/award/yoajjLJ9rFWdLj9PNp4h8yd8",
        img: "./g-metricas.png",
        titulo: "Certificação em métricas do Google Ads",
        recebido: "Mar 02,2022",
        expira: "Mar 02, 2023",
    }
]